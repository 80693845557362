<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="会议视频列表"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <cell-group  v-if="active>0&&!user_empty">
            <cell v-for="(idx,index) in mettingVideos" :title="idx.name" :key="index"
                  is-link @click="showVideos(idx)"  >               
            </cell>
        </cell-group>

        <empty v-if="nodata&&!user_empty" description="查无数据" />
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Cell,Empty,CellGroup,Loading } from 'vant';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {setToken,removeToken,getToken} from '@/utils/auth';
    import {getUser,getMettingVideos} from '@/api/user';

    export default {
        name: 'MettingVideo',
        components: {
            Cell,Empty,CellGroup,Loading
        },
        data(){
            return {
                active:0,
                mettingVideos:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
            };
        },
        computed:{

        },
        methods:{
            showVideos(item){
                this.$router.push({path: '/mettingvideoInfo',query:{id:item.id}});
            },
            initData(){
                getMettingVideos({pid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.length;

                        this.mettingVideos=response.data;
                        console.log(response.data)

                        if(this.mettingVideos.length==0){
                            this.nodata = true
                        }else{
                            this.nodata = false
                        }
                    }
                });
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');

            },
        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            getUser().then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();
                this.user_empty = false

                this.initData();

              }
            });
          }else{
            //生产环境
            var query = this.$route.query;
            let codeTemp = getToken();
            if(query.code || this.getQueryString('code') || codeTemp || query.patientid){
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              let patientid=query.patientid;
              console.log(patientid)
              getUser({patientid:patientid}).then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();
                  this.user_empty = false

                  this.initData();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }


            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
